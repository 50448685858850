// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar__link {
  padding: 10px 0;
  margin-bottom: 5px;
}

.sidebar__link:hover {
  color: #e1488d !important;
}
.sidebar__link--active {
  color: rgb(255, 255, 255);
  background-color: rgba(255, 255, 255, 0.3) !important;
  border-radius: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;EACzB,qDAAqD;EACrD,kBAAkB;AACpB","sourcesContent":[".sidebar__link {\r\n  padding: 10px 0;\r\n  margin-bottom: 5px;\r\n}\r\n\r\n.sidebar__link:hover {\r\n  color: #e1488d !important;\r\n}\r\n.sidebar__link--active {\r\n  color: rgb(255, 255, 255);\r\n  background-color: rgba(255, 255, 255, 0.3) !important;\r\n  border-radius: 8px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
