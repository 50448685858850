import axios from "axios";

export const oauthInstance = axios.create({
  baseURL: process.env.REACT_APP_API_OAUTH_BASE_URL,
});

const requests = {
  signUp: "/sign-up",
  login: "/login",
  loginThroughToken: `/login-through-token`,
  getToken: `/get-access-token`,
  getTokenForUrl: `/get-access-token-for-url`,
  addCustomerReference: "/add-customer-reference",
  updateDetails: "/update-details",
  requestToResetPassword: "/request-for-reset-password",
  verifyResetPasswordLink: "/verify-reset-password-link",
  resetPassword: "/reset-password",
};

type SignUpPropsType = {
  Email: string;
  FirstName: string;
  Surname: string;
  Mobile: string;
  Password: string;
};

export const signUp = (params: SignUpPropsType) => {
  const { Email, FirstName, Surname, Mobile, Password } = params;
  return oauthInstance
    .post(requests.signUp, {
      email: Email,
      firstName: FirstName,
      surName: Surname,
      mobileNo: Mobile,
      password: Password,
    })
    .then((response) => {
      if (response?.data?.accessToken) {
        oauthInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response?.data?.accessToken}`;
      }
      return response;
    })
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type LoginPropsType = {
  email: string;
  password: string;
};

export const login = (params: LoginPropsType) => {
  const { email, password } = params;
  return oauthInstance
    .post(requests.login, {
      email,
      password,
    })
    .then((response) => {
      if (response?.data?.accessToken) {
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response?.data?.accessToken}`;
        oauthInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response?.data?.accessToken}`;
      }
      return response;
    })
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

export const loginThroughToken = (accessToken: string) => {
  return oauthInstance
    .post(requests.loginThroughToken, {
      accessToken,
    })
    .then((response) => response)
    .catch((err) => {
      if (err) {
        throw new Error(err?.response?.data?.message || err);
      }
    });
};

export const getNewAccessToken = (refreshToken: string) => {
  return oauthInstance
    .post(requests.getToken, {
      refreshToken,
    })
    .then((response) => {
      if (response?.data?.accessToken) {
        oauthInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response?.data?.accessToken}`;
      }
      return response;
    })
    .catch((err) => {
      if (err) {
        throw new Error(err?.response?.data?.message || err);
      }
    });
};

export const getAccessTokenForUrl = (url: string) => {
  return oauthInstance
    .post(requests.getTokenForUrl, {
      url,
    })
    .then((response) => {
      if (response?.data?.accessToken) {
        oauthInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response?.data?.accessToken}`;
      }
      return response;
    })
    .catch((err) => {
      if (err) {
        throw new Error(err?.response?.data?.message || err);
      }
    });
};

export const addCustomerReference = ({
  email,
  customerReference,
  CaseReference,
}: {
  email: string;
  customerReference: string;
  CaseReference?: string;
}) => {
  if (!oauthInstance.defaults.headers.common["Authorization"])
    oauthInstance.defaults.headers.common["Authorization"] = `Bearer ${
      JSON.parse(localStorage?.getItem("uAuth") || "")?.accessToken
    }`;
  return oauthInstance
    .post(requests.addCustomerReference, {
      email,
      customerReference,
      CaseReference,
    })
    .then((response) => response)
    .catch((err) => {
      if (err) {
        throw new Error(err?.response?.data?.message || err);
      }
    });
};

export const updateDetails = ({
  email,
  password,
}: {
  email?: string;
  password?: string;
}) => {
  if (!oauthInstance.defaults.headers.common["Authorization"])
    console.log(
      "axios.defaults.headers.common",
      axios.defaults.headers.common["Authorization"],
      "oauthInstance.defaults.headers.common",
      oauthInstance.defaults.headers.common["Authorization"]
    );
  oauthInstance.defaults.headers.common["Authorization"] = `Bearer ${
    JSON.parse(localStorage?.getItem("uAuth") || "")?.accessToken
  }`;
  return oauthInstance
    .patch(requests.updateDetails, {
      email,
      password,
    })
    .then((response) => {
      if (response?.data?.accessToken) {
        oauthInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response?.data?.accessToken}`;
      }
      return response;
    })
    .catch((err) => {
      if (err) {
        throw new Error(err?.response?.data?.message || err);
      }
    });
};

export const requestToResetPassword = ({ email }: { email?: string }) => {
  return oauthInstance
    .post(requests.requestToResetPassword, {
      email,
    })
    .then((response) => response)
    .catch((err) => {
      if (err) {
        throw new Error(err?.response?.data?.message || err);
      }
    });
};

export const verifyResetPasswordLink = ({ token }: { token?: string }) => {
  return oauthInstance
    .post(requests.verifyResetPasswordLink, {
      token,
    })
    .then((response) => response)
    .catch((err) => {
      if (err) {
        throw new Error(err?.response?.data?.message || err);
      }
    });
};

export const resetPassword = ({
  email,
  password,
  token
}: {
  email?: string;
  password?: string;
  token:string
}) => {
  return oauthInstance
    .post(requests.resetPassword, {
      email,
      password,
      token
    })
    .then((response) => response)
    .catch((err) => {
      if (err) {
        throw new Error(err?.response?.data?.message || err);
      }
    });
};
