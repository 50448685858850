import React from "react";
import axios from "axios";
import * as CryptoJS from "crypto-js";
import { getUserToken } from "../utils";

const requests = {
  createCase: "/CreateCaseJSON",
  updateCase: "/UpdateCase",
  invokeADPCall: "/InvokeADPCall",
  openBankingHandShakeInvoke: "/OpenBankingHandShakeInvoke",
  getCase: "/GetCase",
  getOpenBankingStatus: "/GetOpenBankingStatus",
  getADPData: "/GetADPData",
  searchCustomers: "/SearchCustomers",
  fetchifyEmailVerification: "/FetchifyEmailVerification",
  fetchifyMobileVerification: "/FetchifyMobileVerification",
  createDocumentToPdf: "/CreateDocumentToPdf",
  updateCaseLockPaymentPlan: "/UpdateCaseLockPaymentPlan",
  updateCustomer: "/UpdateCustomer",
  getFinancialTransactions: "/GetFinancialTransactions",
  ordoCollectPayment: "/OrdoCollectPayment",
  getInvoiceTerms: "/GetInvoiceTerms",
  updatePaymentPlanHolidays: "/UpdatePaymentPlanHolidays",
  caseRecalculatePaymentPlan: "/CaseRecalculatePaymentPlan",
  getPostcodeLookup: "/GetPostcodeLookup",
  accessPaysuiteIsStartDateValidatForContract:
    "/AccessPaysuiteIsStartDateValidatForContract",
  isAccessPaysuiteCustomerCreated: "/IsAccessPaysuiteCustomerCreated",
  accessPaysuiteCreateCustomer: "/AccessPaysuiteCreateCustomer",
  isAccessPaysuiteContractCreated: "/IsAccessPaysuiteContractCreated",
  accessPaysuiteCreateContract: "/AccessPaysuiteCreateContract",
  accessPaysuiteCancelContract: "/AccessPaysuiteCancelContract",
  ordoVRPCreateMandate: "/OrdoVRPCreateMandate",
  ordoVRPCancelMandate: "/OrdoVRPCancelMandate",
};

export const crmInstanceMiddleServer = axios.create({
  baseURL: process.env.REACT_APP_API_CRM_URL,
});

type CreateCasePropsType = {
  FirstName: string;
  Surname: string;
  Mobile: string;
  Email: string;
};

export const createCase = (params: CreateCasePropsType) => {
  const { FirstName, Surname, Mobile, Email } = params;

  return crmInstanceMiddleServer
    .post(requests.createCase, {
      CaseType: "LoansMort",
      CaseParams: {
        Params: {
          MatterType: "Unsecured-Loan",
          Provider: "Custom Credit",
          ProviderProduct: "Unsecured Loan.",
          TotalLoan: "0",
          Term: "36",
          TermPeriod: "M",
          InterestYearly: "24.9",
          Source: "Online Application",
          CaseStatus: "Initial Enquiry",
          CalculateFinancialField: true,
          CustomerDetails: {
            CustomerCode: "",
            B2B: "false",
            Title: "",
            FirstName,
            Surname,
            Source: "Online Application",
            Telephone: Mobile,
            Mobile,
            Email,
            ClientStatus: "Prospect",
            IsDeduplicationReq: true,
            DeduplicationCheckReq: true,
          },
        },
      },
      accessToken: getUserToken(),
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type UpdateCasePropsType = {
  ApplicantID: string;
  CustomerReference: string;
  Payload: any;
};

export const updateCase = (params: UpdateCasePropsType) => {
  const { ApplicantID, CustomerReference, Payload } = params;

  return crmInstanceMiddleServer
    .post(requests.updateCase, {
      CaseApp: {
        IsUpdateCaseCustomer: true,
        ApplicantID,
        CustomerReference,
        // CaseStatus: "Prospect",
        CalculateFinancialField: true,
        AvoidUpdatingBlankData: true,
        ...Payload,
      },
      accessToken: getUserToken(),
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type UpdateCaseCancellablePropsType = {
  ApplicantID: string;
  CustomerReference: string;
  Payload: any;
};

let cancelTokenForUpdateCaseCancellable: any;

export const updateCaseCancellable = (
  params: UpdateCaseCancellablePropsType
) => {
  const { ApplicantID, CustomerReference, Payload } = params;

  //Check if there are any previous pending requests
  if (typeof cancelTokenForUpdateCaseCancellable != typeof undefined) {
    cancelTokenForUpdateCaseCancellable.cancel(
      "Operation canceled due to new request."
    );
  }

  //Save the cancel token for the current request
  cancelTokenForUpdateCaseCancellable = axios.CancelToken.source();

  return crmInstanceMiddleServer
    .post(
      "updateCaseCancellable",
      {
        CaseApp: {
          IsUpdateCaseCustomer: true,
          ApplicantID,
          CustomerReference,
          // CaseStatus: "Prospect",
          CalculateFinancialField: true,
          AvoidUpdatingBlankData: true,
          ...Payload,
        },
        accessToken: getUserToken(),
      },
      {
        cancelToken: cancelTokenForUpdateCaseCancellable.token,
      }
    )
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type InvokeADPCallPropsType = {
  Payload: any;
};

export const invokeADPCall = (params: InvokeADPCallPropsType) => {
  const { Payload } = params;

  return crmInstanceMiddleServer
    .post(requests.invokeADPCall, {
      pRequest: {
        ...Payload,
      },
      accessToken: getUserToken(),
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

//API openBankingHandShakeInvoke is called after re-connect button
type OpenBankingHandShakeInvokePropsType = {
  pRequest: any;
};

export const openBankingHandShakeInvoke = (
  params: OpenBankingHandShakeInvokePropsType
) => {
  const { pRequest } = params;

  return crmInstanceMiddleServer
    .post(requests.openBankingHandShakeInvoke, {
      pRequest,
      accessToken: getUserToken(),
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

//API getCase is called after searchCustomers
type GetCasePropsType = {
  CaseReference: string;
};

export const getCase = (params: GetCasePropsType) => {
  const { CaseReference } = params;

  return crmInstanceMiddleServer
    .post(requests.getCase, {
      CaseReference: CaseReference,
      accessToken: getUserToken(),
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

//API GetOpenBankingStatus called after click on open banking link
type GetOpenBankingStatusPropsType = {
  pRequest: any;
};

export const getOpenBankingStatus = (params: GetOpenBankingStatusPropsType) => {
  const { pRequest } = params;

  return crmInstanceMiddleServer
    .post(requests.getOpenBankingStatus, {
      pRequest,
      accessToken: getUserToken(),
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

//API getADPData called after click on open banking link
export const getADPData = (params: GetCasePropsType) => {
  const { CaseReference } = params;

  return crmInstanceMiddleServer
    .post(requests.getADPData, {
      CaseReference: CaseReference,
      accessToken: getUserToken(),
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

//API searchCustomers called after login
type SearchCustomerPropsType = { CustomerReference: string };

export const searchCustomers = (params: SearchCustomerPropsType) => {
  const { CustomerReference } = params;

  return crmInstanceMiddleServer
    .post(requests.searchCustomers, {
      SearchParam: CustomerReference,
      accessToken: getUserToken(),
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type VerifyEmailPropsType = { value: string };

export const verifyEmail = (params: VerifyEmailPropsType) => {
  const { value } = params;

  return crmInstanceMiddleServer
    .post(requests.fetchifyEmailVerification, {
      pRequest: { Mode: "email", value },
      accessToken: getUserToken(),
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type VerifyMobilePropsType = { value: string };

export const verifyMobile = (params: VerifyMobilePropsType) => {
  const { value } = params;

  return crmInstanceMiddleServer
    .post(requests.fetchifyMobileVerification, {
      pRequest: {
        Mode: "mobile",
        value,
        ISOCountryCode2: "GB",
      },
      accessToken: getUserToken(),
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type CreateDocumentToPdfPropsType = { ApplicantID: string; LetterCode: string };

export const createDocumentToPdf = (params: CreateDocumentToPdfPropsType) => {
  const { ApplicantID, LetterCode } = params;

  return crmInstanceMiddleServer
    .post(requests.createDocumentToPdf, {
      pRequest: {
        Mode: "Case",
        Reference: ApplicantID,
        LetterCode,
        LetterGroupCode: "",
        ReturnDocType: "PDF",
      },
      accessToken: getUserToken(),
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type UpdateCaseLockPaymentPlanType = {
  ApplicantID: string;
};

export const updateCaseLockPaymentPlan = (
  params: UpdateCaseLockPaymentPlanType
) => {
  const { ApplicantID } = params;

  return crmInstanceMiddleServer
    .post(requests.updateCaseLockPaymentPlan, {
      CaseReference: ApplicantID,
      accessToken: getUserToken(),
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type UpdateCustomerPropsType = {
  CustomerReference: string;
  Email: string;
  Mobile: string;
  IsUpdateCaseCustomer?: boolean;
  DateOfBirth?: string;
  CustomerAddress?: object;
  BankDetail?: object;
  BankDetails?: object;
};

export const updateCustomer = (params: UpdateCustomerPropsType) => {
  const { CustomerReference, Email, Mobile, ...restProps } = params;

  return crmInstanceMiddleServer
    .post(requests.updateCustomer, {
      Customer: {
        CustomerReference,
        Email,
        Mobile: Mobile.toString(),
        AvoidUpdatingBlankData: true,
        ...restProps,
      },
      accessToken: getUserToken(),
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type GetFinancialTransactionsPropsType = {
  Reference: string;
  TransactionType: string;
  DateFrom?: string;
  DateTo?: string;
};

export const getFinancialTransactions = (
  params: GetFinancialTransactionsPropsType
) => {
  const {
    Reference = "",
    TransactionType = "",
    DateFrom = "",
    DateTo = "",
  } = params;

  return crmInstanceMiddleServer
    .post(requests.getFinancialTransactions, {
      Reference,
      TransactionType,
      MaxRecords: "5000",
      DateFrom: DateFrom ? DateFrom : "",
      DateTo: DateTo ? DateTo : "",
      accessToken: getUserToken(),
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

// APIs for Direct Debit Payment Option
type AccessPaysuiteIsStartDateValidatForContractType = {
  StartDate: string;
};

export const accessPaysuiteIsStartDateValidatForContract = (
  params: AccessPaysuiteIsStartDateValidatForContractType
) => {
  const { StartDate } = params;

  return crmInstanceMiddleServer
    .post(requests.accessPaysuiteIsStartDateValidatForContract, {
      StartDate,
      accessToken: getUserToken(),
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type IsAccessPaysuiteCustomerCreatedType = {
  ApplicantID: string;
};

export const isAccessPaysuiteCustomerCreated = (
  params: IsAccessPaysuiteCustomerCreatedType
) => {
  const { ApplicantID } = params;

  return crmInstanceMiddleServer
    .post(requests.isAccessPaysuiteCustomerCreated, {
      ApplicantID,
      accessToken: getUserToken(),
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type AccessPaysuiteCreateCustomerType = {
  ApplicantID: string;
  Customer: {
    Title: string;
    FirstName: string;
    Surname?: string;
    DateOfBirth: string | null;
    Email: string;
    HomePhoneNumber?: string;
    WorkPhoneNumber?: string;
    MobilePhoneNumber?: string;
    AddressLine1: string;
    AddressLine2?: string;
    AddressLine3?: string;
    AddressLine4?: string;
    PostCode: string;
    AccountHolderName: string;
    AccountNumber: string;
    SortCode: string;
  };
};

export const accessPaysuiteCreateCustomer = (
  params: AccessPaysuiteCreateCustomerType
) => {
  const { ApplicantID, Customer } = params;

  return crmInstanceMiddleServer
    .post(requests.accessPaysuiteCreateCustomer, {
      ApplicantID,
      Customer,
      accessToken: getUserToken(),
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type IsAccessPaysuiteContractCreatedType = {
  ApplicantID: string;
};

export const isAccessPaysuiteContractCreated = (
  params: IsAccessPaysuiteContractCreatedType
) => {
  const { ApplicantID } = params;

  return crmInstanceMiddleServer
    .post(requests.isAccessPaysuiteContractCreated, {
      ApplicantID,
      accessToken: getUserToken(),
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type AccessPaysuiteCreateContractType = {
  ApplicantID: string;
  ScheduleId: string;
};

export const accessPaysuiteCreateContract = (
  params: AccessPaysuiteCreateContractType
) => {
  const { ApplicantID, ScheduleId } = params;

  return crmInstanceMiddleServer
    .post(requests.accessPaysuiteCreateContract, {
      ApplicantID,
      ScheduleId,
      accessToken: getUserToken(),
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

// APIs for VRP Payment Option
type OrdoVRPCreateMandateType = {
  MandateData: {
    CaseReference: string;
    PeriodTypeLimitAmount: number;
    MaximumIndividualAmount: number;
  };
};

export const ordoVRPCreateMandate = (params: OrdoVRPCreateMandateType) => {
  const { MandateData } = params;

  return crmInstanceMiddleServer
    .post(requests.ordoVRPCreateMandate, {
      MandateData,
      accessToken: getUserToken(),
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type OrdoCollectPaymentPropsType = {
  CaseReference: string;
  Amount: string;
};

export const ordoCollectPayment = (params: OrdoCollectPaymentPropsType) => {
  const { CaseReference, Amount } = params;

  return crmInstanceMiddleServer
    .post(requests.ordoCollectPayment, {
      PaymentInfo: {
        CaseReference,
        Amount,
        DueDate: "",
        ExpiryDate: "",
      },
      accessToken: getUserToken(),
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type OrdoVRPCancelMandatePropsType = {
  CaseReference: string;
};

export const ordoVRPCancelMandate = (params: OrdoVRPCancelMandatePropsType) => {
  const { CaseReference } = params;

  return crmInstanceMiddleServer
    .post(requests.ordoVRPCancelMandate, {
      CaseReference,
      accessToken: getUserToken(),
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type AccessPaysuiteCancelContractPropsType = {
  ApplicantID: string;
};

export const accessPaysuiteCancelContract = (
  params: AccessPaysuiteCancelContractPropsType
) => {
  const { ApplicantID } = params;

  return crmInstanceMiddleServer
    .post(requests.accessPaysuiteCancelContract, {
      ApplicantID,
      accessToken: getUserToken(),
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type GetInvoiceTermsPropsType = {
  Reference: string;
};

export const getInvoiceTerms = (params: GetInvoiceTermsPropsType) => {
  const { Reference } = params;

  return crmInstanceMiddleServer
    .post(requests.getInvoiceTerms, {
      IncludeRefunds: true,
      Reference,
      accessToken: getUserToken(),
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type UpdatePaymentPlanHolidaysPropsType = {
  CaseReference: string;
  ActualDate: string;
  HolidayPeriod: string;
};

export const updatePaymentPlanHolidays = (
  params: UpdatePaymentPlanHolidaysPropsType
) => {
  const { CaseReference, ActualDate, HolidayPeriod } = params;

  return crmInstanceMiddleServer
    .post(requests.updatePaymentPlanHolidays, {
      pRequest: {
        CaseReference,
        ActualDate,
        HolidayPeriod,
        OnlyCapitalHoliday: "false",
      },
      accessToken: getUserToken(),
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type CaseRecalculatePaymentPlanPropsType = {
  CaseRef: string;
  ActualDate: string;
  Amount: string;
};

export const caseRecalculatePaymentPlan = (
  params: CaseRecalculatePaymentPlanPropsType
) => {
  const { CaseRef, ActualDate, Amount } = params;

  return crmInstanceMiddleServer
    .post(requests.caseRecalculatePaymentPlan, {
      pRequest: {
        CaseRef,
        RecalculatePaymentPlanBasedOn: "Amount",
        ExpectedDate: ActualDate,
        Amount,
        RepaymentType: null,
        RepaymentTypeTermUnit: "Monthly",
        RepaymentMethod: null,
        ActualDate,
      },
      accessToken: getUserToken(),
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type GetPostcodeLookupPropsType = { PostCode: string };

export const getPostcodeLookup = (params: GetPostcodeLookupPropsType) => {
  const { PostCode } = params;

  return crmInstanceMiddleServer
    .post(requests.getPostcodeLookup, {
      pRequest: {
        PostCode,
        CountryCode: "GBP",
      },
      accessToken: getUserToken(),
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};
