// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  display: flex;
  height: 100vh;
}
.circle2 {
  /* width: 100px;
  height: 100px;
  background: lightblue;
  position: absolute; */
  position: absolute;
  border-radius: 50%;
  right: -65px;
  top: -121px;
  background: linear-gradient(
    142.41deg,
    rgba(60, 35, 219, 0.45) -23.34%,
    rgba(0, 219, 222, 0.88) 38.55%
  );
  width: 193px;
  height: 196px;
}
.circle1 {
  /* width: 100px;
  height: 100px;
  background: lightblue;
  position: absolute; */
  position: absolute;
  border-radius: 50%;
  right: -117px;
  top: -70px;
  background: linear-gradient(
    123.67deg,
    #e1488d 26.5%,
    rgba(163, 35, 210, 0.63) 89.03%
  );
  width: 193px;
  height: 196px;
}

::-webkit-outer-spin-button,
::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.leftSideMessage {
  max-width: 60%;
  white-space: pre-wrap;
}

@media only screen and (max-width: 600px) {
  .addLogoIcon {
    background: linear-gradient(to right, #e25192, #cc45ae) !important;
  }
  .addLogoIcon h1 {
    color: white !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;AACf;AACA;EACE;;;uBAGqB;EACrB,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX;;;;GAIC;EACD,YAAY;EACZ,aAAa;AACf;AACA;EACE;;;uBAGqB;EACrB,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;EACb,UAAU;EACV;;;;GAIC;EACD,YAAY;EACZ,aAAa;AACf;;AAEA;;EAEE,wBAAwB;EACxB,aAAa;AACf;;AAEA;;EAEE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE;IACE,kEAAkE;EACpE;EACA;IACE,uBAAuB;EACzB;AACF","sourcesContent":[".App {\r\n  display: flex;\r\n  height: 100vh;\r\n}\r\n.circle2 {\r\n  /* width: 100px;\r\n  height: 100px;\r\n  background: lightblue;\r\n  position: absolute; */\r\n  position: absolute;\r\n  border-radius: 50%;\r\n  right: -65px;\r\n  top: -121px;\r\n  background: linear-gradient(\r\n    142.41deg,\r\n    rgba(60, 35, 219, 0.45) -23.34%,\r\n    rgba(0, 219, 222, 0.88) 38.55%\r\n  );\r\n  width: 193px;\r\n  height: 196px;\r\n}\r\n.circle1 {\r\n  /* width: 100px;\r\n  height: 100px;\r\n  background: lightblue;\r\n  position: absolute; */\r\n  position: absolute;\r\n  border-radius: 50%;\r\n  right: -117px;\r\n  top: -70px;\r\n  background: linear-gradient(\r\n    123.67deg,\r\n    #e1488d 26.5%,\r\n    rgba(163, 35, 210, 0.63) 89.03%\r\n  );\r\n  width: 193px;\r\n  height: 196px;\r\n}\r\n\r\n::-webkit-outer-spin-button,\r\n::-webkit-inner-spin-button {\r\n  -webkit-appearance: none;\r\n  display: none;\r\n}\r\n\r\ninput::-ms-reveal,\r\ninput::-ms-clear {\r\n  display: none;\r\n}\r\n\r\n.leftSideMessage {\r\n  max-width: 60%;\r\n  white-space: pre-wrap;\r\n}\r\n\r\n@media only screen and (max-width: 600px) {\r\n  .addLogoIcon {\r\n    background: linear-gradient(to right, #e25192, #cc45ae) !important;\r\n  }\r\n  .addLogoIcon h1 {\r\n    color: white !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
