import { useCallback, useEffect, useState } from "react";
import { useAuthContext } from "../context/AuthContext/AuthContext";
import {
  CASEINFO_ACTIONS_TYPES,
  useCaseInfoContext,
  useUpdateCaseInfoContext,
} from "../context/CaseContext/CaseContext";
import { getCase, searchCustomers } from "../api/crm";
import { getUrlParam, getUserToken } from "../utils";
import { getAccessTokenForUrl } from "../api/oauth";

const useFetchingInitialData = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useAuthContext();
  const caseInfoDispatch = useUpdateCaseInfoContext();
  const { caseInfoState } = useCaseInfoContext();

  const getAccessTokenByUrl = async () => {
    const url = window.location.search;
    const userData = JSON.parse(localStorage?.getItem("uAuth") || "{}");
    await getAccessTokenForUrl(url.substring(1))
      .then((response) => {
        if (response?.data?.accessToken) {
          localStorage.setItem(
            "uAuth",
            JSON.stringify({
              ...userData,
              accessToken: response?.data?.accessToken,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchCaseInfo = useCallback(async () => {
    setLoading(true);
    if (user?.customerReference || getUrlParam("CustomerReference")) {
      if (getUrlParam("CustomerReference")) {
        await getAccessTokenByUrl();
      }
      searchCustomers({
        CustomerReference:
          user?.customerReference ?? getUrlParam("CustomerReference"),
      })
        .then((response) => {
          caseInfoDispatch({
            type: CASEINFO_ACTIONS_TYPES.ADD_CASE_INDENTIFIERS,
            payload: {
              ...response?.data?.d?.Records?.[0],
            },
          });
          const promises = response?.data?.d?.Records?.[0]?.CustomerCases?.map(
            (item: any) => {
              return getCase({
                CaseReference:
                  item?.CaseRef ||
                  caseInfoState?.CustomerDetails?.ApplicantID ||
                  "CA000072",
              })
                .then((response) => {
                  if (Object.keys(response?.data?.d?.CaseApplicant).length)
                    return response?.data?.d?.CaseApplicant;
                })
                .catch((err) => {
                  console.log(
                    err?.data?.d?.EnquiryResult?.Message ||
                      err?.message ||
                      "Something went wrong"
                  );
                });
            }
          );
          Promise.all(promises)
            .then((result) => {
              const filteredResults = result.filter(
                (item) => typeof item === "object"
              );
              caseInfoDispatch({
                type: CASEINFO_ACTIONS_TYPES.FETCHED_CASELIST,
                payload: filteredResults,
              });
              caseInfoDispatch({
                type: CASEINFO_ACTIONS_TYPES.ADD_CASE_INDENTIFIERS,
                payload: {
                  ApplicantID:
                    filteredResults[filteredResults.length - 1]?.ApplicantID,
                  CustomerReference:
                    filteredResults[filteredResults.length - 1]
                      ?.CustomerReference,
                  [filteredResults[filteredResults.length - 1]?.ApplicantID]: {
                    ...filteredResults[filteredResults.length - 1],
                  },
                },
              });
              setIsFetching(true);
            })
            .catch((err) => console.log("Something went wrong"))
            .finally(() => {
              setLoading(false);
              setIsFetching(false);
            });
        })
        .catch((err) => {
          console.log(
            err?.data?.d?.EnquiryResult?.Message ||
              err?.message ||
              "Something went wrong"
          );
          setLoading(false);
          setIsFetching(false);
        });
    } else {
      setIsFetching(false);
      setLoading(false);
    }
  }, [
    caseInfoDispatch,
    caseInfoState?.CustomerDetails?.ApplicantID,
    user?.customerReference,
  ]);

  useEffect(() => {
    if (!caseInfoState?.CustomerDetails?.ApplicantID) fetchCaseInfo();
  }, [fetchCaseInfo, caseInfoState?.CustomerDetails?.ApplicantID]);

  return { isLoading: loading || isFetching };
};

export default useFetchingInitialData;
