import React, { useCallback, useEffect, useRef } from "react";
import { CircularProgress, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { additionalUrlParams, getUrlParam } from "../../utils";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import { getOpenBankingStatus, invokeADPCall, updateCase } from "../../api/crm";
import ImageLayoutContainer from "../../components/ImageLayoutContainer/ImageLayoutContainer";
import CenterColumnContainer from "../../components/Containers";
import { SmallWidthColumnContainer } from "../../components/Containers";
import CircularProgressWithLabel from "../../utils/CircularProgressWithLabel";
import {
  CASEINFO_ACTIONS_TYPES,
  useCaseInfoContext,
  useUpdateCaseInfoContext,
} from "../../context/CaseContext/CaseContext";
import { ErrorOutline } from "@mui/icons-material";

const OpenBanking3 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const intervalIdRef = useRef<number | null>(null);
  const [toastMessage, setToastMessage] = React.useState("");
  const [adpCallProgress, setAdpCallProgress] = React.useState(5);
  const [isLoading, setIsLoading] = React.useState(true);
  const { CustomerDetails = {} } = useCaseInfoContext();

  const OpenBankingId =
    location?.state?.OpenBankingId ||
    location?.state?.caseDetails?.DynamicFieldDetail?.find(
      (field: any) => field?.FieldNo === "22"
    )?.FieldValue;
  const caseInfoDispatch = useUpdateCaseInfoContext();

  const checkStatus = useCallback(() => {
    setIsLoading(true);

    if (!CustomerDetails.ApplicantID && getUrlParam("ApplicantID")) {
      CustomerDetails.ApplicantID = getUrlParam("ApplicantID");
      CustomerDetails.CustomerReference = getUrlParam("CustomerReference");
    }

    if (!CustomerDetails?.CustomerReference || !CustomerDetails?.ApplicantID)
      return setIsLoading(false);

    getOpenBankingStatus({
      pRequest: {
        CustomerCode: CustomerDetails?.CustomerReference,
        OpenBankingId,
        IsCheckWithThirdPartyRequired: true,
      },
    })
      .then((response) => {
        const isStatusCompleted =
          response?.data?.d?.Records?.[response?.data?.d?.Records.length - 1]
            ?.Status === "Complete" || false;

        if (isStatusCompleted) {
          intervalIdRef.current && clearInterval(intervalIdRef.current);
          caseInfoDispatch({
            type: CASEINFO_ACTIONS_TYPES.FETCHED_OPEN_BANKING_STATUS,
            payload: response?.data?.d?.Records,
          });
          caseInfoDispatch({
            type: CASEINFO_ACTIONS_TYPES.ADD_CASE_INDENTIFIERS,
            payload: {
              OpenBankingCurrentStatus:
                response?.data?.d?.Records?.[
                  response?.data?.d?.Records.length - 1
                ]?.Status,
            },
          });

          const adpCallNames = ["CAT 11", "CAT 12", "CAT 13", "CAT 14"];
          const asuncUpdatedAdpCalls = async (index: number) => {
            const adpCallName = adpCallNames[index];

            if (index === adpCallNames.length || !adpCallName) {
              return navigate(
                "/welcome-after-open-banking" + additionalUrlParams()
              );
            }

            invokeADPCall({
              Payload: {
                AdpCallName: adpCallName,
                CaseRef: CustomerDetails?.ApplicantID,
              },
            })
              .then(({ data }) => {
                const recordValue = data?.d?.Records?.find((record: any) =>
                  record?.FieldName?.includes(adpCallName)
                )?.Value;

                if (recordValue === "Decline Live") {
                  // update API to change Cases Status
                  updateCase({
                    ApplicantID: CustomerDetails?.ApplicantID,
                    CustomerReference: CustomerDetails?.CustomerReference,
                    Payload: {
                      WorkflowStep: "9",
                      CaseStatus: "Declined",
                    },
                  })
                    .then((responseData) => {
                      if (
                        responseData?.data?.d?.UpdateResult?.SuccessCode === 0
                      )
                        return navigate(
                          "/failed-credit-checks" + additionalUrlParams(),
                          {
                            state: {
                              submitBtnURL: "/home",
                              submitBtnText: "Go to Dashboard",
                            },
                          }
                        );
                    })
                    .catch((err) => {
                      setToastMessage(
                        err?.data?.d?.EnquiryResult?.Message ||
                          err?.message ||
                          "Something went wrong"
                      );
                    });
                }
                const nextIndex = index + 1;
                let progressInPercentage =
                  (100 * nextIndex) / adpCallNames.length;
                setAdpCallProgress(progressInPercentage);
                return asuncUpdatedAdpCalls(nextIndex);
              })
              .catch((error) => {
                setToastMessage(
                  "There has been an error while completing our checks, please contact us on 0800 802 1466 for assistance with your application"
                );
                setIsLoading(false);
              });
          };
          asuncUpdatedAdpCalls(0);
        }
      })
      .catch((err: any) => {
        setToastMessage(
          err?.data?.d?.EnquiryResult?.Message ||
            err?.message ||
            "Something went wrong"
        );
        setIsLoading(false);
      });
  }, [
    CustomerDetails?.ApplicantID,
    CustomerDetails?.CustomerReference,
    OpenBankingId,
    caseInfoDispatch,
    navigate,
  ]);

  const handleSubmit = (values: any) => {};

  useEffect(() => {
    const interval: any = setInterval(checkStatus, 50000);
    intervalIdRef.current = interval;
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <ImageLayoutContainer
      noPadding
      title="OPEN BANKING"
      handleClick={handleSubmit}
      btnText="Refresh" // Text for the Button placed at the bottom of the page. (Usually displayed as Next Button)
    >
      <ToastMessage message={toastMessage} setMessage={setToastMessage} />
      <CenterColumnContainer>
        <SmallWidthColumnContainer
          flex="1"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Typography className="!mt-10 !text-xs !w-5/6">
            If your bank confirmed the Open Banking authorisation was
            successful, it may take a few minutes to update us. When 100%
            complete (as indicated below), you will be taken to the next step of
            your application. We appreciate your patience.
          </Typography>
          <div className="relative w-full" style={{ height: "300px" }}>
            <div
              className="absolute"
              style={{
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              {isLoading ? (
                // <CircularProgress color="primary" size="45px" />
                <CircularProgressWithLabel value={adpCallProgress} />
              ) : (
                <ErrorOutline color="primary" sx={{ fontSize: "75px" }} />
              )}
            </div>
          </div>
        </SmallWidthColumnContainer>
      </CenterColumnContainer>
    </ImageLayoutContainer>
  );
};

export default OpenBanking3;
