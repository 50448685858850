import React from "react";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { additionalUrlParams, getUrlParam } from "../../utils";
import CenterColumnContainer, {
  SmallWidthColumnContainer,
} from "../../components/Containers";
import {
  CASEINFO_ACTIONS_TYPES,
  useCaseInfoContext,
  useUpdateCaseInfoContext,
} from "../../context/CaseContext/CaseContext";
import { getOpenBankingStatus, updateCase } from "../../api/crm";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import ImageLayoutContainer from "../../components/ImageLayoutContainer/ImageLayoutContainer";

const WelcomAfterOpenBanking = () => {
  const navigate = useNavigate();
  const [toastMessage, setToastMessage] = React.useState("");
  const {
    OpenBanking2Fields = {},
    CustomerDetails = {},
    ...caseInfoState
  } = useCaseInfoContext();
  const caseInfoDispatch = useUpdateCaseInfoContext();

  const handleSubmit = () => {
    updateCase({
      ApplicantID: CustomerDetails?.ApplicantID ?? getUrlParam("ApplicantID"),
      CustomerReference:
        CustomerDetails?.CustomerReference ?? getUrlParam("CustomerReference"),
      Payload: {
        WorkflowStep: "4",
        CaseStatus: "Finalise Loan",
      },
    });
    // if (CustomerDetails?.ApplicantID)
    //   updateCase({
    //     ApplicantID: CustomerDetails?.ApplicantID,
    //     CustomerReference: "",
    //     Payload: {
    //       CaseStatus: "",
    //       "WorkflowStep": "4.3",
    //     },
    //   })
    //     .then((responseData) => {
    //       if (responseData?.data?.d?.UpdateResult?.SuccessCode === 0)
    //       else return navigate("/failed-credit-checks");
    //     })
    //     .catch((err) => {
    //       setToastMessage(
    //         err?.data?.d?.EnquiryResult?.Message ||
    //         err?.message ||
    //         "Something went wrong"
    //         );
    //       });
    return navigate("/loan-details" + additionalUrlParams());
  };

  const fetchOpenBankingStatus = React.useCallback(() => {
    if (
      (caseInfoState?.CustomerDetails?.CustomerReference ||
        getUrlParam("CustomerReference")) &&
      OpenBanking2Fields?.BankCode &&
      OpenBanking2Fields?.ApplicantNumber
    )
      getOpenBankingStatus({
        pRequest: {
          CustomerCode:
            caseInfoState?.CustomerDetails?.CustomerReference ??
            getUrlParam("CustomerReference"),
          BankCode: OpenBanking2Fields?.BankCode,
          ApplicantNumber: OpenBanking2Fields?.ApplicantNumber,
        },
      })
        .then((response) => {
          if (response?.data?.d?.Records.length)
            caseInfoDispatch({
              type: CASEINFO_ACTIONS_TYPES.FETCHED_OPEN_BANKING_STATUS,
              payload: response?.data?.d?.Records,
            });
          else setToastMessage("No records found.");
        })
        .catch((err) =>
          setToastMessage(
            err?.data?.d?.EnquiryResult?.Message ||
              err?.message ||
              "Something went wrong"
          )
        );
  }, [
    OpenBanking2Fields?.ApplicantNumber,
    OpenBanking2Fields?.BankCode,
    caseInfoDispatch,
    caseInfoState,
  ]);

  React.useEffect(() => {
    if (
      !caseInfoState?.OpenBankingStatus ||
      !Object.keys(caseInfoState?.OpenBankingStatus).length
    )
      fetchOpenBankingStatus();
  }, [caseInfoState?.OpenBankingStatus, fetchOpenBankingStatus]);

  return (
    <ImageLayoutContainer noPadding title="Welcome" handleClick={handleSubmit}>
      <ToastMessage message={toastMessage} setMessage={setToastMessage} />
      <CenterColumnContainer flex="1">
        <SmallWidthColumnContainer flex="1" justifyContent="flex-start">
          <Typography variant="h5" className="!text-lg !mt-12">
            Welcome back to
            <br />
            Custom Credit.
          </Typography>
          <Typography className="!text-xs !mt-4">
            You have successfully authorised connection to your bank account.
          </Typography>
          <Typography className="!text-xs !mt-4">
            You can revoke authorisation at any time by logging into your bank
            account and reviewing your Open Banking connections.
          </Typography>
        </SmallWidthColumnContainer>
      </CenterColumnContainer>
    </ImageLayoutContainer>
  );
};

export default WelcomAfterOpenBanking;
